import { Tooltip } from 'antd';
import React from 'react';

// General text truncation component
export const TruncatedText = ({ text, maxLength = 24 }) => {
	const shouldTruncate = text.length > maxLength;
	const truncatedText = shouldTruncate ? `${text.substring(0, maxLength)}...` : text;

	const headingStyle = {
		fontSize: '24px',
		fontWeight: 500,
		color: '#27272A',
		margin: 0,
		lineHeight: '32px',
		letterSpacing: '-0.5px'
	};

	if (!shouldTruncate) {
		return <h2 style={headingStyle}>{text}</h2>;
	}

	return (
		<Tooltip title={text} placement="top">
			<h2 style={headingStyle}>{truncatedText}</h2>
		</Tooltip>
	);
};

// Specific component for truncating IDs
export const TruncatedId = ({ id, maxLength = 18 }) => {
	const shouldTruncate = id.length > maxLength;
	const truncatedId = shouldTruncate ? `${id.substring(0, maxLength)}...` : id;

	if (!shouldTruncate) {
		return (
			<span style={{ color: '#71717A', fontSize: '16px', fontWeight: 400 }}>
				{'ID: '}
				{id}
			</span>
		);
	}

	return (
		<Tooltip title={`ID: ${id}`} placement="top">
			<span style={{ color: '#71717A', fontSize: '16px', fontWeight: 400 }}>
				{'ID: '}
				{truncatedId}
			</span>
		</Tooltip>
	);
};
