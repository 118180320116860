import {
	CheckSquareOutlined,
	CloseSquareOutlined,
	DeleteOutlined,
	WarningOutlined
} from '@ant-design/icons';
import { Button, message, Pagination, Popconfirm, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import SortBySetting from '../containers/SortBySetting';
import Uploads from '../containers/Uploads';
import NoPhotosPlaceholder from './NoPhotosPlaceholder';
import PhotoComp from './PhotoComp';

const { Option } = Select;

class PhotoListComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			inSelectMode: false,
			selectedPhotos: new Set(),
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	moveToAlbum = async (albumDocId) => {
		console.log(albumDocId, 'albumDocId');
		const { clickedEvent, clickedAlbum, movePhotos } = this.props;
		try {
			this.setState({ loading: true });
			await movePhotos(clickedEvent, clickedAlbum, albumDocId, [...this.state.selectedPhotos]);
			this.setState({ loading: false, selectedPhotos: new Set(), inSelectMode: false });
			message.success('Photos are moved!');
		} catch (e) {
			message.error(e.response.body.message);
			this.setState({ loading: false });
		}
	};

	deleteSelectedPhotos = async () => {
		const { clickedEvent, clickedAlbum, deletePhotos } = this.props;
		try {
			this.setState({ loading: true });
			await deletePhotos(clickedEvent, clickedAlbum, [...this.state.selectedPhotos]);
			this.setState({ loading: false, selectedPhotos: new Set(), inSelectMode: false });
			message.success('Photos are deleted!');
		} catch (e) {
			message.error(e.response.body.message);
			this.setState({ loading: false });
		}
	};

	deletePopOver = (
		<div>
			<p>Are you sure?</p>
			<p>This will permanently delete this photo(s).</p>
		</div>
	);

	getActionComp = () => {
		const { photos, visibleAlbums, clickedAlbum } = this.props;
		if (photos?.length > 0) {
			let moveableAlbums = [];
			if (clickedAlbum?.id && visibleAlbums?.length > 0)
				moveableAlbums = visibleAlbums?.filter((album) => album.id !== clickedAlbum.id);
			return (
				<div>
					{this.state.selectedPhotos.size > 0 &&
					moveableAlbums.length > 0 &&
					!clickedAlbum?.isGuestUploads ? (
						<Select
							placeholder="Move to.."
							disabled={this.state.loading}
							loading={this.state.loading}
							style={{ width: '120px', marginRight: '8px' }}
							onChange={this.moveToAlbum}>
							{moveableAlbums.map((album, index) => (
								<Option key={index} value={album.id} title={null}>
									{album.name}
								</Option>
							))}
						</Select>
					) : null}
					{this.state.selectedPhotos?.size > 0 ? (
						<Popconfirm
							title={this.deletePopOver}
							icon={<WarningOutlined />}
							onConfirm={this.deleteSelectedPhotos}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Delete Anyway!"
							cancelText="Cancel">
							<Button disabled={this.state.loading} loading={this.state.loading}>
								Delete
							</Button>
						</Popconfirm>
					) : null}
					<Button
						disabled={this.state.loading}
						style={{ marginRight: '20px', marginLeft: '8px', width: '75px' }}
						onClick={(e) => {
							e.preventDefault();
							this.setState({ inSelectMode: !this.state.inSelectMode });
							if (this.state.inSelectMode) {
								this.setState({ selectedPhotos: new Set() });
							}
						}}>
						{this.state.inSelectMode ? 'Cancel' : 'Select'}
					</Button>
				</div>
			);
		}
		return null;
	};

	getPaginationComp = () => {
		const {
			clickedEvent,
			clickedAlbum,
			currentPage,
			currentAlbumPhotoCount,
			photoPerPage,
			photos,
			pageUpdated,
			perPagePhotoCountChanged
		} = this.props;

		if (photos?.length > 0) {
			return (
				<Pagination
					total={currentAlbumPhotoCount}
					pageSize={photoPerPage}
					current={currentPage}
					showSizeChanger
					pageSizeOptions={['50', '500', '1000', '2000']}
					onShowSizeChange={(_, pageSize) => {
						perPagePhotoCountChanged(pageSize);
					}}
					onChange={(page, _) => {
						pageUpdated(clickedEvent, clickedAlbum, page);
					}}
				/>
			);
		}

		return null;
	};

	getPhotoGridComp = () => {
		const {
			clickedEvent,
			photos,
			onPhotoClick,
			logoutNow,
			albumIndex,
			currentAlbumPhotoCount,
			isLoading
		} = this.props;

		console.log('PhotoListComp: Rendering photo grid', {
			photosLength: photos?.length,
			currentAlbumPhotoCount,
			isLoading
		});

		if (isLoading) {
			console.log('PhotoListComp: Showing loading spinner');
			return (
				<div className="photos-grid-loading">
					<Spin size="large" />
				</div>
			);
		}

		if (!photos?.length) {
			return <NoPhotosPlaceholder />;
		}

		return (
			<div className="photos-grid">
				{photos?.map((photo, index) => (
					<PhotoComp
						className={`card photos-grid-item ${
							this.state.selectedPhotos.has(photo.id) ? 'selected' : ''
						}`}
						key={photo.id}
						isSelected={this.state.selectedPhotos.has(photo.id)}
						photo={photo}
						isThumbnail
						onClick={() => {
							if (this.state.inSelectMode) {
								console.log(this.state.selectedPhotos);
								let _selectedPhotos = new Set(this.state.selectedPhotos);
								if (this.state.selectedPhotos.has(photo.id)) {
									_selectedPhotos.delete(photo.id);
								} else {
									_selectedPhotos.add(photo.id);
								}
								this.setState({ selectedPhotos: _selectedPhotos });
							} else {
								onPhotoClick(index, albumIndex, clickedEvent.id);
							}
						}}
						logoutNow={() => {
							logoutNow();
						}}
					/>
				))}
			</div>
		);
	};

	render() {
		const { className, clickedAlbum, currentAlbumPhotoCount } = this.props;

		return (
			<div className={`photo-list-component ${this.state.isMobile ? 'mobile' : className}`}>
				{this.state.isMobile && (
					<div className="mobile-header">
						<h2 className="album-title">{`${clickedAlbum?.name} (${
							currentAlbumPhotoCount || 0
						})`}</h2>
						<div className="mobile-actions">
							<SortBySetting />
							{this.state.inSelectMode ? (
								<>
									<CloseSquareOutlined
										className="action-icon"
										onClick={() => {
											this.setState({ inSelectMode: false, selectedPhotos: new Set() });
										}}
									/>
									{this.state.selectedPhotos.size > 0 && (
										<Popconfirm
											title={this.deletePopOver}
											icon={<WarningOutlined />}
											onConfirm={this.deleteSelectedPhotos}
											onCancel={(e) => {
												e.stopPropagation();
											}}
											okText="Delete Anyway!"
											cancelText="Cancel">
											<DeleteOutlined className="action-icon" />
										</Popconfirm>
									)}
								</>
							) : (
								<CheckSquareOutlined
									className="action-icon"
									onClick={() => {
										this.setState({ inSelectMode: true });
									}}
								/>
							)}
						</div>
					</div>
				)}
				{!clickedAlbum?.isGuestUploads && <Uploads />}
				{!this.state.isMobile && (
					<div className="event-home-photos-title">
						<div className="event-home-photos-title-name">
							<h2>{clickedAlbum?.name}</h2>
							<p>{currentAlbumPhotoCount > 0 ? `${currentAlbumPhotoCount} photos` : 'No photos'}</p>
						</div>
						{this.getActionComp()}
						{this.getPaginationComp()}
					</div>
				)}
				{this.getPhotoGridComp()}
				{this.state.isMobile && <div className="mobile-pagination">{this.getPaginationComp()}</div>}
			</div>
		);
	}
}

PhotoListComp.propTypes = {
	photos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired
		}).isRequired
	).isRequired,
	currentPage: PropTypes.number.isRequired,
	onPhotoClick: PropTypes.func.isRequired,
	pageUpdated: PropTypes.func.isRequired,
	photoPerPage: PropTypes.number.isRequired,
	currentAlbumPhotoCount: PropTypes.number,
	clickedEvent: PropTypes.shape({
		name: PropTypes.string.isRequired
	}).isRequired,
	clickedAlbum: PropTypes.shape({
		name: PropTypes.string.isRequired
	}).isRequired,
	guestUpload: PropTypes.func.isRequired,
	albumIndex: PropTypes.number.isRequired,
	logoutNow: PropTypes.func.isRequired,
	perPagePhotoCountChanged: PropTypes.func.isRequired,
	visibleAlbums: PropTypes.array,
	deletePhotos: PropTypes.func.isRequired,
	movePhotos: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default PhotoListComp;
