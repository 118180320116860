import { Card, Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { calculateActualUploadLimit, formatCount } from '../utils/subscriptionUtils';

const SubscriptionCard = ({ subscription }) => {
	const formatExpiryDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-GB', {
			day: 'numeric',
			month: 'short',
			year: 'numeric'
		});
	};

	const getDaysLeft = (dateString) => {
		const now = new Date();
		const expiry = new Date(dateString);
		const timeDiff = expiry.getTime() - now.getTime();
		const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysLeft > 0 ? daysLeft : 0;
	};

	const actualUploadLimit = calculateActualUploadLimit(subscription);

	return (
		<Card
			title="Subscription Details"
			style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
			bodyStyle={{
				flexGrow: 1,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: '20px'
			}}>
			<div>
				<p
					style={{
						fontWeight: 'bold',
						textAlign: 'center',
						fontSize: '1em',
						marginBottom: '5px',
						marginTop: '0'
					}}>
					{subscription.name}
				</p>
				<div style={{ lineHeight: '1.8' }}>
					<p>
						<strong>Status:</strong>{' '}
						<Tag color={subscription.status === 'active' ? 'green' : 'red'}>
							{subscription.status}
						</Tag>
					</p>
					{/* <p>
						<strong>Tier:</strong> {subscription.tier}
					</p> */}
					<p>
						<strong>Valid till:</strong> {formatExpiryDate(subscription.expiresAt)}{' '}
						<span style={{ color: 'gray' }}>({getDaysLeft(subscription.expiresAt)} days left)</span>
					</p>

					{/* <Divider style={{ margin: '12px 0' }} /> */}

					<div style={{ fontSize: '14px', borderTop: '1px solid #f0f0f0', paddingTop: '2px' }}>
						{/* Upload Capacity with actual remaining limit */}
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
							<span>Upload Capacity:</span>
							<span
								style={{
									color: actualUploadLimit <= 0 ? '#ff4d4f' : '#333'
								}}>
								{formatCount(actualUploadLimit)} / {formatCount(subscription.uploadLimit)}
								{actualUploadLimit <= 0 && (
									<span style={{ marginLeft: '4px', fontSize: '12px', color: '#ff4d4f' }}>
										(Limit reached)
									</span>
								)}
							</span>
						</div>

						{/* Current Photos */}
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
							<span>Current Photos:</span>
							<span
								style={{
									color:
										subscription.currentPhotosCount >= subscription.maxPhotosLimit
											? '#ff4d4f'
											: '#333'
								}}>
								{formatCount(subscription.currentPhotosCount)} /{' '}
								{formatCount(subscription.maxPhotosLimit)}
							</span>
						</div>

						{/* Guest Photos */}
						<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
							<span>Reserved for Guests:</span>
							<span
								style={{
									color:
										subscription.guestPhotosCount >= subscription.guestPhotosLimit
											? '#ff4d4f'
											: '#333'
								}}>
								{/* {formatCount(subscription.guestPhotosCount)} /{' '} */}
								{formatCount(subscription.guestPhotosLimit)}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div style={{ borderTop: '1px solid #f0f0f0', paddingTop: '2px' }}>
				<strong>Features:</strong> Access to all features
			</div>
		</Card>
	);
};

SubscriptionCard.propTypes = {
	subscription: PropTypes.shape({
		name: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		tier: PropTypes.string.isRequired,
		expiresAt: PropTypes.string.isRequired,
		maxPhotosLimit: PropTypes.number.isRequired,
		uploadLimit: PropTypes.number.isRequired,
		uploadedPhotosCount: PropTypes.number.isRequired,
		currentPhotosCount: PropTypes.number.isRequired,
		guestPhotosLimit: PropTypes.number.isRequired,
		guestPhotosCount: PropTypes.number.isRequired
	}).isRequired
};

export default SubscriptionCard;
