export const EVENT_TYPES = {
	PACK_BASED: 0,
	SUBSCRIPTION_BASED: 1
};

export const validateSubscriptionUpload = (event, subscription, uploadCount = 1) => {
	if (!event || !subscription) return false;

	// Check subscription active and not expired
	const isSubscriptionActive =
		subscription.status === 'active' && new Date(subscription.expiresAt) > new Date();

	if (!isSubscriptionActive) return false;

	// Check upload limits
	const hasUploadLimit = subscription.uploadedPhotosCount + uploadCount <= subscription.uploadLimit;
	const hasStorageLimit =
		subscription.currentPhotosCount + uploadCount <= subscription.maxPhotosLimit;

	return hasUploadLimit && hasStorageLimit;
};

export const getUploadLimits = (event, subscription) => {
	if (!event) return null;

	if (event.eventType === EVENT_TYPES.SUBSCRIPTION_BASED) {
		if (!subscription) return null;
		return {
			uploadLimit: subscription.uploadLimit - subscription.uploadedPhotosCount,
			storageLimit: subscription.maxPhotosLimit - subscription.currentPhotosCount,
			total: subscription.maxPhotosLimit
		};
	}

	// Pack-based event
	const maxPhotos = parseInt(event.maxPhotos);
	const guestMaxPhotos = event.guestMaxPhotos || 0;
	const currentPhotos = event.currentPhotosCount || 0;
	const remaining = maxPhotos - guestMaxPhotos - currentPhotos;

	return {
		uploadLimit: remaining,
		storageLimit: remaining,
		total: maxPhotos - guestMaxPhotos
	};
};

export const formatPhotoCount = (event, subscription) => {
	if (!event) return '-- / --';

	if (event.eventType === EVENT_TYPES.SUBSCRIPTION_BASED && subscription) {
		return `${subscription.currentPhotosCount} / ${subscription.maxPhotosLimit}`;
	}

	const maxPhotos = parseInt(event.maxPhotos);
	const guestMaxPhotos = event.guestMaxPhotos || 0;
	return `${event.currentPhotosCount || 0} / ${maxPhotos - guestMaxPhotos}`;
};

export const calculateActualUploadLimit = (subscription) => {
	if (!subscription) return 0;

	// Calculate remaining capacity based on upload limit
	const remainingByUploadLimit =
		subscription.uploadLimit - subscription.uploadedPhotosCount - subscription.guestPhotosLimit;

	// Calculate remaining capacity based on storage limit
	const remainingByStorageLimit =
		subscription.maxPhotosLimit - subscription.currentPhotosCount - subscription.guestPhotosLimit;

	// Return the lower of the two limits
	return Math.max(0, Math.min(remainingByUploadLimit, remainingByStorageLimit));
};
