import { errorHandler, swaggerClient } from '../../swagger';

class Subscription {
	static fetchSubscription = async (userId, subscriptionId) => {
		console.log('Fetching subscription for user', userId, 'with subscriptionId', subscriptionId);
		try {
			const result = await swaggerClient().apis.subscription.getSubscriptionById({
				userId: userId,
				subscriptionId: subscriptionId
			});
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	};
}

export default Subscription;
