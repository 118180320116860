import { goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateMyEventsMAction } from '../actions/pouchDBActions';
import CreateEventComp from '../components/CreateEventComp';
import Event from '../pouchDB/models/Event';
import Purchase from '../pouchDB/models/Purchase';

const mapStateToProps = (state) => ({
	userId: state.auth.userId,
	email: state.auth.email,
	whitelabelId: state.auth.whitelabelId,
	subscription: state.auth.subscription
});

const mapDispatchToProps = (dispatch) => ({
	goBack: () => {
		dispatch(goBack());
	},
	fetchPurchasedPacks: async (userId, whitelabelId, consistent) => {
		return await Purchase.fetchPurchases(userId, whitelabelId, consistent);
	},
	createEvent: async (userId, eventData) => {
		return await Event.createEvent(userId, eventData);
	},
	eventCreated: async () => {
		await dispatch(updateMyEventsMAction(1, true));
		dispatch(goBack());
	}
});

const CreateEvent = connect(mapStateToProps, mapDispatchToProps)(CreateEventComp);

export default CreateEvent;
