// File: src/components/ContentLayout.js

import PropTypes from 'prop-types';
import React from 'react';

const ContentLayout = ({ pathname, children }) => {
	// Check if we're on an event details page
	const isEventDetailsPage = pathname.match(/^\/events\/[^/]+$/);

	return (
		<div
			className="with-nav-body"
			style={{
				'--top-margin': isEventDetailsPage ? '12px' : '46px'
			}}>
			{children}
		</div>
	);
};

ContentLayout.propTypes = {
	pathname: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default ContentLayout;
