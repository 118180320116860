import { Tag } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

const EventPhotoStats = ({ event, eventPhotoCount }) => {
	const isSubscriptionEvent = event.eventType === 1;

	// Use eventPhotoCount from props if available, fallback to event.currentPhotosCount
	const currentCount =
		typeof eventPhotoCount !== 'undefined' ? eventPhotoCount : event.currentPhotosCount || 0;

	if (isSubscriptionEvent) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', gap: 8, margin: '0 20px' }}>
				<Tag color="blue">Subscription</Tag>
				<span style={{ color: '#71717A', fontSize: '16px' }}>Photos: {currentCount}</span>
			</div>
		);
	}

	// For pack-based events, show current/total
	const totalAllowed = event.maxPhotos - (event.guestMaxPhotos || 0);
	return (
		<span style={{ color: '#71717A', fontSize: '16px', margin: '0 20px' }}>
			Photos: {currentCount}/{totalAllowed}
		</span>
	);
};

// Match the exact state mapping from EventHomeComp
const mapStateToProps = (state, ownProps) => ({
	eventPhotoCount:
		state.pouchDB.visibleEvent && state.pouchDB.photoCounts[ownProps.event.id]
			? state.pouchDB.photoCounts[ownProps.event.id].count
			: undefined
});

export default connect(mapStateToProps)(EventPhotoStats);
