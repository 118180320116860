// src/utils/subscriptionUtils.js

/**
 * Calculates the actual remaining upload limit based on subscription constraints
 * @param {Object} subscription - The subscription object containing limits and current usage
 * @returns {number} The actual number of photos that can still be uploaded
 */
export const calculateActualUploadLimit = (subscription) => {
	if (!subscription) return 0;

	// Calculate remaining capacity based on upload limit
	const remainingByUploadLimit =
		subscription.uploadLimit - subscription.uploadedPhotosCount - subscription.guestPhotosLimit;

	// Calculate remaining capacity based on storage limit
	const remainingByStorageLimit =
		subscription.maxPhotosLimit - subscription.currentPhotosCount - subscription.guestPhotosLimit;

	// Return the lower of the two limits
	return Math.max(0, Math.min(remainingByUploadLimit, remainingByStorageLimit));
};

/**
 * Formats a number with locale-specific thousand separators
 * @param {number} count - The number to format
 * @returns {string} Formatted number string
 */
export const formatCount = (count) => {
	return count.toLocaleString();
};
