import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CircleTitle extends Component {
	render() {
		const { title, className } = this.props;
		return (
			<div className={classnames('text-head-line-holder', className)}>
				{/* <div className="text-head-line"></div> */}
				{/* <div className='text-head-circle'></div>  */}
				<h4>{title}</h4>
				{/* <div className='text-head-circle'></div> */}
				{/* <div className="text-head-line "></div> */}
			</div>
		);
	}
}

CircleTitle.propTypes = {
	title: PropTypes.string.isRequired
};

export default CircleTitle;
