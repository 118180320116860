import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, ColorPicker, Row, Switch, Upload, message } from 'antd';
import QRCodeStyling from 'qr-code-styling';
import React, { useEffect, useRef, useState } from 'react';
import Profile from '../pouchDB/models/Profile';

import defaultLogoImage from '../svg/kamero_logo.svg';
const defaultQRColor = '#6F4898';
const MAX_LOGO_SIZE = 80 * 1024; // 80 KB in bytes

const QRCodeGenerator = ({ data, auth, eventName }) => {
	const [qrCodeDataURL, setQrCodeDataURL] = useState('');
	const qrCodeRef = useRef(null);
	const [logoSrc, setLogoSrc] = useState(defaultLogoImage);
	const [qrColor, setQrColor] = useState(defaultQRColor);
	const [showLogo, setShowLogo] = useState(true);
	const [customLogoFile, setCustomLogoFile] = useState(null);
	const [profileData, setProfileData] = useState(null);

	const handleCustomLogoUpload = (file) => {
		if (file.size > MAX_LOGO_SIZE) {
			message.error(
				'Only image size up to 80 KB is supported. Please compress the image and upload again.'
			);
			return false;
		}
		setCustomLogoFile(file);
		setShowLogo(true);
		return false; // Prevent upload to server
	};

	useEffect(() => {
		let isSubscribed = true;

		const fetchProfileData = async () => {
			try {
				const profile = await Profile.fetchProfile(auth.userId);

				if (isSubscribed) {
					setProfileData(profile);
				}
			} catch (err) {
				console.error('Failed to fetch profile data:', err);
			}
		};

		fetchProfileData();

		return () => {
			isSubscribed = false;
		};
	}, [auth.userId]);

	const handleLogoVisibilityChange = (checked) => {
		setShowLogo(checked);
	};

	useEffect(() => {
		const fetchLogoImage = async () => {
			let logoSrc = defaultLogoImage;

			if (customLogoFile) {
				logoSrc = URL.createObjectURL(customLogoFile);
			} else if (profileData && profileData.profilePhoto) {
				try {
					const response = await fetch(profileData.profilePhoto);
					if (response.ok) {
						logoSrc = profileData.profilePhoto;
					} else {
						console.warn('Failed to fetch logo image');
					}
				} catch (error) {
					console.error('Error fetching logo image:', error);
				}
			}

			setLogoSrc(logoSrc);
		};

		fetchLogoImage();
	}, [customLogoFile, profileData]);

	useEffect(() => {
		const setQRCodeColor = () => {
			let qrColor = defaultQRColor;

			if (customLogoFile && profileData && profileData.brandColor) {
				qrColor = profileData.brandColor;
			} else if (profileData && profileData.brandColor) {
				qrColor = profileData.brandColor;
			}

			setQrColor(qrColor);
		};

		setQRCodeColor();
	}, [profileData]);

	useEffect(() => {
		const fetchQRCodeData = async () => {
			if (data && logoSrc && qrColor) {
				const qrCodeOptions = {
					width: 250,
					height: 250,
					data: data,
					image: showLogo ? logoSrc : undefined,
					dotsOptions: {
						color: qrColor,
						type: 'dots'
					},
					cornersSquareOptions: {
						type: 'extra-rounded'
					},
					imageOptions: {
						crossOrigin: 'anonymous',
						imageSize: 0.5,
						margin: 3.5
					}
				};

				const qrCode = new QRCodeStyling(qrCodeOptions);
				const qrCodeBlob = await qrCode.getRawData(data);
				const qrCodeDataUrl = await blobToDataURL(qrCodeBlob);
				setQrCodeDataURL(qrCodeDataUrl);
			}
		};

		fetchQRCodeData();
	}, [data, logoSrc, qrColor, showLogo]);

	const blobToDataURL = (blob) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	};

	const renderQRCode = () => {
		if (qrCodeDataURL) {
			return (
				<div
					ref={qrCodeRef}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '20px'
					}}>
					<img src={qrCodeDataURL} alt="QR Code" width="250" height="250" />
				</div>
			);
		}
		return null;
	};

	const generateQRCode = (options) => {
		const baseOptions = {
			width: options.width,
			height: options.height,
			data: data,
			image: showLogo ? logoSrc : undefined,
			dotsOptions: {
				color: qrColor,
				type: 'dots'
			},
			cornersSquareOptions: {
				type: 'extra-rounded'
			},
			imageOptions: {
				crossOrigin: 'anonymous',
				imageSize: 0.5,
				margin: options.margin
			},
			qrOptions: {
				errorCorrectionLevel: 'H'
			},
			backgroundOptions: {
				color: '#ffffff'
			}
		};

		// Add padding
		const padding = Math.floor(options.width * 0.1); // 10% padding
		const qrCodeSize = options.width - 2 * padding;

		return new QRCodeStyling({
			...baseOptions,
			width: options.width,
			height: options.height,
			margin: padding,
			width: qrCodeSize,
			height: qrCodeSize
		});
	};

	const onDownloadPNGClick = () => {
		if (data && logoSrc && qrColor) {
			const qrCode = generateQRCode({ width: 4096, height: 4096, margin: 56 });
			qrCode.download({
				name: `${eventName}_QR_Code`,
				extension: 'png'
			});
		}
	};

	const onDownloadSVGClick = () => {
		if (data && logoSrc && qrColor) {
			const qrCode = generateQRCode({ width: 1024, height: 1024, margin: 14 });
			qrCode.download({
				name: `${eventName}_QR_Code`,
				extension: 'svg'
			});
		}
	};

	return (
		<div>
			{renderQRCode()}

			<Row gutter={16} align="center" style={{ marginTop: '10px' }}>
				<Upload accept="image/*" beforeUpload={handleCustomLogoUpload} showUploadList={false}>
					<Button icon={<UploadOutlined />}>
						{customLogoFile ? customLogoFile.name : 'Upload Custom Logo'}
					</Button>
				</Upload>

				{customLogoFile && (
					<Button
						icon={<CloseOutlined />}
						onClick={() => {
							setCustomLogoFile(null);
						}}
						style={{ marginLeft: '10px' }}></Button>
				)}
			</Row>

			<Row gutter={16} align="space-between" style={{ marginTop: '10px' }}>
				<Col>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<h5 style={{ marginRight: '8px' }}>QR Code Color:</h5>
						<ColorPicker
							value={qrColor}
							onChange={(_, hex) => {
								setQrColor(hex);
							}}
							showText
						/>
					</div>
				</Col>
				<Col>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<h5 style={{ marginRight: '8px' }}>Show Logo:</h5>
						<Switch checked={showLogo} onChange={handleLogoVisibilityChange} />
					</div>
				</Col>
			</Row>

			<Row gutter={16} align="center" style={{ marginTop: '14px' }}>
				<Button type="default" onClick={onDownloadPNGClick} style={{ marginRight: '10px' }}>
					Download PNG QR
				</Button>
				<Button type="default" onClick={onDownloadSVGClick}>
					Download SVG QR
				</Button>
			</Row>
		</div>
	);
};

export default QRCodeGenerator;
