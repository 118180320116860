import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SectionTitle extends Component {
	render() {
		const { title, className } = this.props;
		return (
			<div
				className={classnames('text-head-line-holder', className)}
				style={{
					position: 'relative',
					paddingBottom: '8px' // Added padding for underline spacing
				}}>
				<h4
					style={{
						fontSize: '14px',
						fontWeight: '600',
						color: '#18181B',
						padding: '8px 0',
						margin: 0,
						letterSpacing: '0.05em',
						textTransform: 'uppercase',
						position: 'relative',
						display: 'inline-block' // Makes the underline width match text
					}}>
					{title}
					<span
						style={{
							content: '""',
							position: 'absolute',
							bottom: 0,
							left: 0,
							width: '100%',
							height: '2px',
							background: 'linear-gradient(to right, #6F4898 70%, transparent)',
							borderRadius: '2px'
						}}
					/>
				</h4>
			</div>
		);
	}
}

SectionTitle.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default SectionTitle;
