import { Col, Drawer, Layout, Menu, message, Modal, Row } from 'antd';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiErrorOccured } from '../actions/pouchDBActions';
import FullScreenIndicator from '../components/FullScreenIndicator';
import { ReactComponent as ReactAppIcon } from '../svg/icon_kamero.svg';
import { ReactComponent as ReactAppIconPurple } from '../svg/kamero_logo_purple.svg';

import { Home, WalletMinimal } from 'lucide-react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { logout, needsLogin } from '../actions/loginActions';
import ContentLayout from '../components/ContentLayout';
import OrderListComp from '../components/OrderListComp';
import PostListComp from '../components/PostListComp';
import SetWatermark from '../components/SetWatermark';
import ProfileContainer from '../containers/ProfileContainer';
import CreateEvent from './CreateEvent';
import Dashboard from './Dashboard';
import EventHome from './EventHome';
import MyEvents from './MyEvents';

import {
	GalleryVerticalEnd,
	ListMinus,
	ListOrdered,
	LogOut,
	Menu as MenuIcon,
	Palette,
	User
} from 'lucide-react';

const { Header, Content } = Layout;

const appRoutes = [
	{ path: '/dashboard', label: 'Home', icon: <WalletMinimal /> },
	{ path: '/events', label: 'Events' },
	{ path: '/posts', label: 'Posts' },
	{ path: '/orders', label: 'Orders' },
	{ path: '/setwatermark', label: 'Set Watermark' },
	{ path: '/profile/create', label: 'Profile' }
];

const items = [
	{
		key: 'home',
		label: 'home',
		icon: <Home />
	},
	{
		key: 'events',
		label: 'Events',
		icon: <GalleryVerticalEnd />
	},
	{
		key: 'post',
		label: 'Posts',
		icon: <ListMinus />
	},
	{
		key: 'setwatermark',
		label: 'Set Watermark',
		icon: <Palette />
	},
	{
		key: 'orders',
		label: 'Orders',
		icon: <ListOrdered />
	},
	{
		key: 'profile',
		label: 'Profile',
		icon: <User />
	},
	{
		key: 'logout',
		label: 'Logout',
		icon: <LogOut />
	}
];

class AppWithNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576,
			drawerOpen: false,
			logoutModalVisible: false
		};
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { lastAuthError, loginError } = nextProps;
		if (lastAuthError) {
			loginError();
		}
	}

	componentDidUpdate() {
		this.showAPIErrorIfNeeded();
	}

	showAPIErrorIfNeeded = () => {
		var { apiError, apiErrorOccured } = this.props;
		if (apiError) {
			message.error(apiError, 8);
			apiErrorOccured(undefined);
		}
	};

	handleMenuClick = (event) => {
		const { goHome, goToEvents, goToPosts, goToSetWatermark, goToOrders, goToProfile } = this.props;
		this.toggleDrawer();

		switch (event.key) {
			case 'home':
			case 'dashboard':
				goHome();
				break;
			case 'events':
				goToEvents();
				break;
			case 'post':
				goToPosts();
				break;
			case 'setwatermark':
				goToSetWatermark();
				break;
			case 'orders':
				goToOrders();
				break;
			case 'profile':
				goToProfile();
				break;
			case 'logout':
				this.showLogoutConfirmation();
				break;
			default:
				break;
		}
	};

	getPageTitleFromPath() {
		const path = this.props.location.pathname;
		if (path) {
			const item = appRoutes.find((x) => x.path === path);
			if (!item && path.startsWith('/events')) return 'hidden';
			return item ? item.label : path;
		}
	}

	toggleDrawer() {
		this.setState((prev) => ({ drawerOpen: !prev.drawerOpen }));
	}

	showLogoutConfirmation = () => {
		this.setState({ logoutModalVisible: true });
	};

	handleLogoutConfirm = () => {
		this.setState({ logoutModalVisible: false });
		this.props.logoutButtonClicked();
	};

	handleLogoutCancel = () => {
		this.setState({ logoutModalVisible: false });
	};

	render() {
		const { whitelabelId, user, userId, email } = this.props;
		const { logoutModalVisible } = this.state;

		if (!user || !userId) {
			return (
				<div>
					<FullScreenIndicator />
				</div>
			);
		}

		return (
			<Layout className="nav-plus-content api-error-parent">
				{this.getPageTitleFromPath() !== 'hidden' && (
					<>
						{this.state.isMobile ? (
							<>
								<Row
									style={{
										padding: '8px',
										position: 'fixed',
										zIndex: 3,
										width: '100%',
										backgroundColor: '#6F4898',
										display: 'flex',
										alignItems: 'center',
										margin: '0 auto'
									}}
									align="space-between"
									justify={'center'}
									justifyContent={'center'}
									gutter={10}>
									<Col>
										<MenuIcon
											style={{
												fontSize: '20px',
												color: 'white',
												marginLeft: '10px',
												marginTop: '5px'
											}}
											onClick={this.toggleDrawer}
										/>
									</Col>
									<Col>
										<h3 style={{ fontWeight: 700, fontSize: '20px', color: 'white' }}>
											{this.getPageTitleFromPath()}
										</h3>
									</Col>
									<ReactAppIcon className="navIconMobile" />
								</Row>
								{console.log('Drawer Open', this.state.drawerOpen)}
								<Drawer
									title={<ReactAppIconPurple className="navIcon" style={{ marginLeft: '10px' }} />}
									placement="left"
									onClose={this.toggleDrawer}
									open={this.state.drawerOpen}>
									<Menu
										onClick={this.handleMenuClick}
										mode="vertical"
										defaultSelectedKeys={['dashboard']}
										items={items}></Menu>
								</Drawer>
							</>
						) : (
							<Header style={{ position: 'fixed', zIndex: 3, width: '100%' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',

										flexDirection: 'row'
									}}>
									<Menu
										onClick={this.handleMenuClick}
										mode="horizontal"
										theme="dark"
										className="navbar"
										style={{ flexGrow: 1 }}>
										<Menu.Item className="navItem" key="home">
											<ReactAppIcon className="navIcon" />
										</Menu.Item>
										<Menu.Item className="navItem" key="dashboard">
											{whitelabelId === 'whitelabel-0' ? 'Home' : 'Home'}
										</Menu.Item>
										<Menu.Item className="navItem" key="events">
											Events
										</Menu.Item>
										<Menu.Item className="navItem" key="post">
											Posts
										</Menu.Item>
										<Menu.Item className="navItem" key="setwatermark">
											Set Watermark
										</Menu.Item>
										<Menu.Item className="navItem" key="orders">
											Orders
										</Menu.Item>
										<Menu.Item className="navItem" key="profile">
											Profile
										</Menu.Item>
										<Menu.Item className="navItem" key="logout">
											Logout
										</Menu.Item>
									</Menu>
									<div
										style={{
											cursor: 'auto',
											userSelect: 'none',
											marginRight: '16px',
											color: 'white'
										}}>
										Welcome {email}{' '}
									</div>
								</div>
							</Header>
						)}
					</>
				)}
				<Modal
					title="Confirm Logout"
					open={logoutModalVisible}
					onOk={this.handleLogoutConfirm}
					onCancel={this.handleLogoutCancel}>
					<p>Are you sure you want to log out?</p>
				</Modal>
				<Content>
					<ContentLayout pathname={this.props.location.pathname}>
						<Switch>
							<Route exact path="/dashboard" component={Dashboard} />
							<Route exact path="/events" component={MyEvents} />
							<Route exact path="/posts" component={PostListComp} />
							<Route exact path="/orders" component={OrderListComp} />
							<Route exact path="/setwatermark" component={SetWatermark} />
							<Route exact path="/events/create" component={CreateEvent} />
							<Route exact path="/profile/create" component={ProfileContainer} />
							<Route path="/events/:eventId" component={EventHome} />
						</Switch>
					</ContentLayout>
				</Content>
			</Layout>
		);
	}
}

AppWithNavigation.propTypes = {
	user: PropTypes.string,
	userId: PropTypes.string,
	email: PropTypes.string,
	whitelabelId: PropTypes.string,
	lastAuthError: PropTypes.string,
	loginError: PropTypes.func.isRequired,
	logoutButtonClicked: PropTypes.func.isRequired,
	goHome: PropTypes.func.isRequired,
	goToEvents: PropTypes.func.isRequired,
	goToPosts: PropTypes.func.isRequired,
	goToSetWatermark: PropTypes.func.isRequired,
	goToOrders: PropTypes.func.isRequired,
	goToProfile: PropTypes.func.isRequired,
	apiError: PropTypes.string,
	apiErrorOccured: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.auth.name,
		userId: state.auth.userId,
		whitelabelId: state.auth.whitelabelId,
		lastAuthError: state.auth.lastError,
		apiError: state.pouchDB.apiError,
		email: state.auth.email
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	logoutButtonClicked: () => {
		dispatch(logout());
	},
	goHome: (e) => {
		dispatch(push('/dashboard'));
	},
	goToEvents: (e) => {
		dispatch(push('/events'));
	},
	goToPosts: () => {
		dispatch(push('/posts'));
	},
	goToSetWatermark: () => {
		dispatch(push('/setwatermark'));
	},
	goToOrders: () => {
		dispatch(push('/orders'));
	},
	goToProfile: () => {
		dispatch(push('/profile/create'));
	},
	apiErrorOccured: (error) => {
		dispatch(apiErrorOccured(error));
	},
	loginError: () => {
		dispatch(needsLogin());
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWithNavigation));
