import { Button, Input, Pagination } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { isEventExpired } from '../helpers/dateUtils.js';
import { EVENT_PAGE_SIZE } from '../pouchDB/models/Event';
import '../styles/EventList.sass';
import { ReactComponent as IndicatorSVG } from '../svg/Indicator.svg';
import { ReactComponent as NoEventsSVG } from '../svg/NoEventsPlaceholder.svg';
import EventComp from './EventComp';
import ExpiringEventsBanner from './ExpiringEventsBanner';
import ExpiringFaceEventsBanner from './ExpiringFaceEventsBanner';
import FullScreenIndicator from './FullScreenIndicator';
const { Search } = Input;

class EventListComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fetchingEvents: false,
			searchingEvents: false,
			searchText: undefined,
			isMobile: window.screen.width <= 576
		};
	}

	noEventsPlaceholder() {
		return (
			<div className="flexCenterFullScreenContainer">
				<div className="flexCenterPageChild">
					<NoEventsSVG />
				</div>
				<div style={{ margin: '7px', marginBottom: '20px' }} className="flexCenterPageChild">
					No events created
				</div>
				<div className="flexCenterPageChild">
					{this.props.whitelabelId ? (
						<Button type="primary" className="pageRightDetail" onClick={this.props.createNewEvent}>
							Create Event
						</Button>
					) : null}
				</div>
			</div>
		);
	}

	eventsSection() {
		const {
			searchedEvents,
			events,
			onEventClick,
			eventNameEdited,
			setEventId,
			deleteEvent
			// editEventExpiry
		} = this.props;

		let eventsToShow = events;

		if (searchedEvents) {
			eventsToShow = searchedEvents;
		}

		if (this.state.fetchingEvents) {
			return (
				<div className="fetching-event">
					<IndicatorSVG />
				</div>
			);
		}

		if (!eventsToShow) {
			return null;
		}

		if (eventsToShow && eventsToShow.length > 0) {
			return (
				<div className="events">
					{eventsToShow.map((event, index) => (
						<EventComp
							key={event.id}
							event={event}
							isExpired={isEventExpired(event.expiresAt)}
							eventNameEdited={(userId, event, newEventName) => {
								eventNameEdited(index, userId, event, newEventName);
							}}
							setEventId={(userId, event, newEventId) => {
								setEventId(index, userId, event, newEventId);
							}}
							// editEventExpiry={async (userId, event, newExpiry) => {
							// 	await editEventExpiry(index, userId, event, newExpiry);
							// }}
							deleteEvent={deleteEvent}
							onClick={() => {
								if (event.state === 'created') {
									onEventClick(event);
								}
							}}
							refreshEvents={() => this.props.refreshEvents(this.props.currentPage)}
						/>
					))}
					{[1, 2, 3, 4, 5].map((value) => (
						<div className="event-card-extra" key={value} />
					))}
				</div>
			);
		} else {
			if (searchedEvents) {
				return (
					<div style={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
						No events found.
					</div>
				);
			} else {
				return this.noEventsPlaceholder();
			}
		}
	}

	render() {
		const {
			userId,
			searchEvents,
			searchedEvents,
			clearSearchedEvents,
			whitelabelId,
			events,
			createNewEvent,
			currentPage,
			totalEvents,
			goToPage
		} = this.props;

		if (!events || totalEvents === undefined) {
			return <FullScreenIndicator />;
		}

		return (
			<div style={{ marginLeft: '5px', marginRight: '5px' }}>
				{this.state.isMobile ? (
					<div
						className="pageTitleBar"
						style={{ marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>
						{whitelabelId && events && events.length > 0 ? (
							<Button
								type="primary"
								className="pageRightDetail"
								style={{ marginTop: '5px' }}
								onClick={createNewEvent}>
								Create Event
							</Button>
						) : null}
					</div>
				) : (
					<div className="pageTitleBar">
						<h1>My Events</h1>
						<>
							{totalEvents > 0 ? (
								<Search
									placeholder="Search by name or event id"
									enterButton="Search"
									loading={this.state.searchingEvents}
									value={this.state.searchText}
									onChange={(e) => {
										this.setState({ searchText: e.target.value });
									}}
									onSearch={(value) => {
										if (this.state.searchingEvents) {
											return;
										}
										this.setState({ searchingEvents: true });
										searchEvents(userId, value).then(() =>
											this.setState({ searchingEvents: false })
										);
									}}
									style={{ width: '650px' }}
								/>
							) : null}
						</>
						{whitelabelId && events && events.length > 0 ? (
							<Button
								type="primary"
								className="pageRightDetail"
								onClick={createNewEvent}
								style={{ maxWidth: '180px' }}>
								Create Event
							</Button>
						) : null}
					</div>
				)}

				<ExpiringEventsBanner onRenewed={() => this.props.refreshEvents(this.props.currentPage)} />

				<div style={{ marginTop: '10px' }}>
					<ExpiringFaceEventsBanner onRenewed={() => {}} />
				</div>

				<div
					style={
						this.state.isMobile
							? {
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '16px',
									marginTop: '5px'
							  }
							: { display: 'flex', justifyContent: 'center', marginBottom: '10px' }
					}>
					{this.state.isMobile ? (
						<>
							{totalEvents > 0 ? (
								<Search
									placeholder="Search by name or event id"
									enterButton="Search"
									loading={this.state.searchingEvents}
									value={this.state.searchText}
									onChange={(e) => {
										this.setState({ searchText: e.target.value });
									}}
									onSearch={(value) => {
										if (this.state.searchingEvents) {
											return;
										}
										this.setState({ searchingEvents: true });
										searchEvents(userId, value).then(() =>
											this.setState({ searchingEvents: false })
										);
									}}
									style={{ width: '600px' }}
								/>
							) : null}
						</>
					) : null}
					{searchedEvents ? (
						<Button
							type="secondary"
							style={{ marginLeft: '8px' }}
							onClick={(e) => {
								e.preventDefault();
								clearSearchedEvents();
								this.setState({ searchText: undefined });
							}}>
							cancel
						</Button>
					) : null}
				</div>
				{this.eventsSection()}
				{!searchedEvents ? (
					<div className="centerHorizontal" style={{ marginTop: '20px', marginBottom: '30px' }}>
						<Pagination
							hideOnSinglePage
							total={totalEvents}
							pageSize={EVENT_PAGE_SIZE}
							current={currentPage}
							onChange={(page, pageSize) => {
								this.setState({ fetchingEvents: true });
								goToPage(page).then(() => this.setState({ fetchingEvents: false }));
							}}
						/>
					</div>
				) : null}
			</div>
		);
	}
}

EventListComp.propTypes = {
	whitelabelId: PropTypes.string,
	clearSearchedEvents: PropTypes.func.isRequired,
	searchEvents: PropTypes.func.isRequired,
	searchedEvents: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			maxPhotos: PropTypes.number,
			createdAt: PropTypes.string.isRequired,
			expiresAt: PropTypes.string,
			eventId: PropTypes.string.isRequired
		}).isRequired
	),
	events: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			maxPhotos: PropTypes.number,
			createdAt: PropTypes.string.isRequired,
			expiresAt: PropTypes.string,
			eventId: PropTypes.string.isRequired
		}).isRequired
	),
	onEventClick: PropTypes.func.isRequired,
	createNewEvent: PropTypes.func.isRequired,
	replicationState: PropTypes.string,
	eventNameEdited: PropTypes.func.isRequired,
	setEventId: PropTypes.func.isRequired,
	deleteEvent: PropTypes.func.isRequired,
	refreshEvents: PropTypes.func.isRequired,
	currentPage: PropTypes.number,
	totalEvents: PropTypes.number,
	// editEventExpiry: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default EventListComp;
