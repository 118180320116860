// File: src/components/ProfileMobile.js

import { MailOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IMAGES_URL } from '../config';
import '../styles/profile.sass';
import coverImg from '../svg/coverImg.png';
import { ReactComponent as Call } from '../svg/ic_call.svg';
import { ReactComponent as Website } from '../svg/ic_website.svg';
import profileImg from '../svg/IconKamero.svg';
import MobileImage from '../svg/mobile.png';

const ImageBaseUrl = IMAGES_URL + '/1/none/high/thumb/?key=';

function ProfileMobile(props) {
	const { profile, selectedIcon } = props;

	return (
		<div className="mobile-preview-container">
			<div
				className="mobile"
				style={{
					backgroundImage: `url(${MobileImage})`
				}}>
				<div
					className="mobileNav"
					style={{
						color:
							profile.brandColor === '#ffffff'
								? '#000000'
								: profile.brandColor === ''
								? '#000000'
								: '#ffffff',
						backgroundColor: profile.brandColor
					}}>
					Event Name
				</div>
				<div
					className="mobileCover"
					style={{
						backgroundImage:
							profile.coverPhoto === ''
								? `url(${coverImg})`
								: `url(${ImageBaseUrl}${profile.coverPhoto})`
					}}>
					PORTFOLIO
				</div>
				<div className="mobileContent">
					<Row gutter={[5, 5]} align="middle">
						<Col
							span={6}
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<div className="mobileProfileImg" style={{ width: '100%', maxWidth: '60px' }}>
								<img
									src={
										profile.profilePhoto === ''
											? profileImg
											: `${ImageBaseUrl}${profile.profilePhoto}`
									}
									alt="Profile"
									style={{ width: '100%', height: 'auto' }}
								/>
							</div>
						</Col>
						<Col span={18} className="mobileCompanyName">
							<div>{profile.companyName}</div>
							{props.validatePersonalDetails() && (
								<div className="personalDetailsIcons">
									<Call fill={profile.brandColor} />
									<MailOutlined style={{ color: profile.brandColor }} />
									<Website fill={profile.brandColor} />
								</div>
							)}
						</Col>
					</Row>
				</div>
				<div className="mobileAboutCompanyContainer">
					<div className="mobileAboutCompany">{profile.aboutCompany}</div>
					<div className="mobileBorder"> </div>
					<div className="iconContainerMob">
						{props.selectedIcon.map((element, index) => (
							<img
								key={index}
								src={element.icon}
								className="iconMob"
								alt={element.name}
								style={{
									width: element.name === 'whatsapp' ? '21px' : '18px',
									height: element.name === 'whatsapp' ? '21px' : '18px'
								}}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	profile: state.profile
});

const ProfileMobileUi = connect(mapStateToProps)(ProfileMobile);

export default ProfileMobileUi;
