import { Button, Card, Col, message, Row, Typography } from 'antd';
import { Package } from 'lucide-react';
import React, { Component } from 'react';
import FullScreenIndicator from './FullScreenIndicator';

const { Title, Text } = Typography;

class PackSelector extends Component {
	state = {
		myPurchases: [],
		showIndicator: false
	};

	componentDidMount() {
		this.fetchPurchases(false);
	}

	fetchPurchases = async (consistent) => {
		const { userId, whitelabelId, fetchPurchasedPacks } = this.props;
		try {
			this.setState({ showIndicator: true });
			let purchases = await fetchPurchasedPacks(userId, whitelabelId, consistent);
			this.setState({ myPurchases: purchases, showIndicator: false });
		} catch (err) {
			message.error(err.message);
			this.setState({ showIndicator: false });
		}
	};

	handlePackSelection = (purchase) => {
		this.props.onPackSelect(purchase);
	};

	handleGoDashboard = () => {
		window.location.href = '/dashboard';
	};

	renderPacks() {
		return (
			<Row
				gutter={[16, 16]}
				justify="center"
				style={{
					width: '100%',
					margin: '0 auto'
				}}>
				{this.state.myPurchases.map((purchase) => (
					<Col xs={24} sm={12} md={8} key={purchase.id}>
						<Card
							hoverable
							onClick={() => this.handlePackSelection(purchase)}
							className="pack-card"
							style={{
								borderRadius: '12px',
								border: '1px solid #e5e7eb',
								cursor: 'pointer',
								transition: 'all 0.2s ease',
								display: 'flex',
								flexDirection: 'column'
							}}
							bodyStyle={{
								padding: '24px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								textAlign: 'center',
								height: '100%',
								justifyContent: 'flex-start'
							}}>
							<div
								className="pack-icon-wrapper"
								style={{
									background: '#6F4898',
									borderRadius: '12px',
									padding: '16px',
									marginBottom: '16px'
								}}>
								<Package size={32} style={{ color: 'white' }} />
							</div>
							<div
								style={{
									flex: 1,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center'
								}}>
								<Title
									level={4}
									style={{
										marginBottom: '16px',
										fontSize: '18px'
									}}>
									{purchase.title}
								</Title>
								<p
									style={{
										fontSize: '14px',
										marginBottom: '8px',
										color: '#6b7280'
									}}>
									{purchase.unusedQuantity === 9999
										? 'UNLIMITED'
										: `${purchase.unusedQuantity} Unused`}
								</p>
								<p
									style={{
										margin: 0,
										fontSize: '14px',
										color: '#6b7280'
									}}>
									{purchase.photos} photos included
								</p>
							</div>
						</Card>
					</Col>
				))}
			</Row>
		);
	}

	render() {
		const { myPurchases, showIndicator } = this.state;

		if (showIndicator) {
			return <FullScreenIndicator />;
		}

		if (!myPurchases || myPurchases.length === 0) {
			return (
				<div className="empty-state">
					<div className="empty-state-content">
						<Title
							level={4}
							style={{
								marginBottom: '8px',
								fontSize: '18px'
							}}>
							No Event Packs Available
						</Title>
						<Text
							style={{
								color: '#6b7280',
								marginBottom: '16px',
								display: 'block',
								fontSize: '14px'
							}}>
							You need to purchase a pack first to create a new event
						</Text>
						<Button
							type="primary"
							onClick={this.handleGoDashboard}
							style={{
								backgroundColor: '#6F4898',
								borderColor: '#6F4898'
							}}>
							Go to Dashboard to Purchase Pack
						</Button>
					</div>
					<style>{`
            .empty-state {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 24px;
              min-height: 200px;
            }
            
            .empty-state-content {
              text-align: center;
              max-width: 400px;
            }
          `}</style>
				</div>
			);
		}

		return (
			<div className="packs">
				<Title
					level={3}
					style={{
						textAlign: 'center',
						marginBottom: '32px'
					}}>
					Select an Event Pack
				</Title>
				{this.renderPacks()}
				<style>{`
          .pack-card {
            box-shadow: 0 1px 3px rgba(111, 72, 152, 0.1);
          }
          
          .pack-card:hover {
            border-color: #6F4898 !important;
            box-shadow: 0 4px 12px rgba(111, 72, 152, 0.15) !important;
            transform: translateY(-4px);
          }

          .empty-state {
            text-align: center;
            padding: 48px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 400px;
          }

          .packs {
            padding: 0 0 24px;
          }
        `}</style>
			</div>
		);
	}
}

export default PackSelector;
