// import { connect } from 'react-redux';
// import { photosUploadedMAction } from '../actions/pouchDBActions';
// import {
// 	uploadErrorOccuredAction,
// 	uploadFinished,
// 	uploadProgressUpdatedAction,
// 	uploadStartedAction
// } from '../actions/uploadActions';
// import UploadComp from '../components/UploadComp';

// const mapStateToProps = (state) => ({
// 	userId: state.auth.userId,
// 	event: state.pouchDB.visibleEvent,
// 	eventPhotoCount:
// 		state.pouchDB.visibleEvent && state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id]
// 			? state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id].count
// 			: undefined,
// 	album: state.pouchDB.clickedAlbum,
// 	uploading: state.upload.uploading,
// 	pending: state.upload.pending,
// 	total: state.upload.total,
// 	error: state.upload.errorDetail,
// 	successMessage: state.upload.successMessage,
// 	subscription: state.auth.subscription
// });

// const mapDispatchToProps = (dispatch) => ({
// 	uploadStarted: (total) => dispatch(uploadStartedAction(total)),
// 	uploadFinished: (message) => {
// 		dispatch(uploadFinished(message));
// 	},
// 	photosUploaded: (event, album) => {
// 		dispatch(photosUploadedMAction(event, album));
// 	},
// 	uploadProgressUpdated: (pending) => dispatch(uploadProgressUpdatedAction(pending)),
// 	uploadError: (error) => {
// 		dispatch(uploadErrorOccuredAction(error));
// 	}
// });

// const Uploads = connect(mapStateToProps, mapDispatchToProps)(UploadComp);

// export default Uploads;

import { connect } from 'react-redux';
import { photosUploadedMAction } from '../actions/pouchDBActions';
import {
	uploadErrorOccuredAction,
	uploadFinished,
	uploadProgressUpdatedAction,
	uploadStartedAction
} from '../actions/uploadActions';
import UploadComp from '../components/UploadComp';

const mapStateToProps = (state) => {
	// Log photo counts
	const eventId = state.pouchDB.visibleEvent?.id;

	const props = {
		userId: state.auth.userId,
		event: state.pouchDB.visibleEvent,
		eventPhotoCount:
			state.pouchDB.visibleEvent && state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id]
				? state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id].count
				: undefined,
		album: state.pouchDB.clickedAlbum,
		uploading: state.upload.uploading,
		pending: state.upload.pending,
		total: state.upload.total,
		error: state.upload.errorDetail,
		successMessage: state.upload.successMessage,
		subscription: state.auth.subscription
	};

	return props;
};

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	uploadStarted: (total) => {
		console.log('Upload Started:', { total });
		dispatch(uploadStartedAction(total));
	},
	uploadFinished: (message) => {
		console.log('Upload Finished:', { message });
		dispatch(uploadFinished(message));
	},
	photosUploaded: (event, album) => {
		console.log('Photos Uploaded:', {
			eventId: event?.id,
			eventType: event?.eventType,
			albumId: album?.id
		});
		dispatch(photosUploadedMAction(event, album));
	},
	uploadProgressUpdated: (pending) => {
		console.log('Upload Progress:', { pending });
		dispatch(uploadProgressUpdatedAction(pending));
	},
	uploadError: (error) => {
		console.error('Upload Error:', { error });
		dispatch(uploadErrorOccuredAction(error));
	}
});

// Add logging when component connects
const Uploads = connect(mapStateToProps, mapDispatchToProps)(UploadComp);

// Add additional debugging info
console.log('Uploads Container Initialized');

export default Uploads;
