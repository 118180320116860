import { connect } from 'react-redux';
import { logout } from '../actions/loginActions';
import {
	deletePhotosMAction,
	movePhotosMAction,
	perPagePhotoCountChangedMAction,
	photosPageSelected,
	updateFullScreenPhotoIndex
} from '../actions/pouchDBActions';
import PhotoListComp from '../components/PhotoListComp';
import Photo from '../pouchDB/models/Photo';

function getAlbumIndex(visibleAlbums, album) {
	if (album === undefined) {
		return undefined;
	}

	if (visibleAlbums?.length > 0) {
		for (var i = 0; i < visibleAlbums.length; i++) {
			if (visibleAlbums[i].id === album.id) {
				return i;
			}
		}
	}

	return undefined;
}

const mapStateToProps = (state) => ({
	currentPage: state.pouchDB.currentPage,
	photoPerPage: Photo.photosPerPage(),
	photos: state.pouchDB.visiblePhotos,
	clickedEvent: state.pouchDB.visibleEvent,
	clickedAlbum: state.pouchDB.clickedAlbum,
	albumIndex: getAlbumIndex(state.pouchDB.visibleAlbums, state.pouchDB.clickedAlbum),
	currentAlbumPhotoCount: state.pouchDB.photoCounts[state.pouchDB.clickedAlbum?.id]
		? state.pouchDB.photoCounts[state.pouchDB.clickedAlbum.id].count
		: undefined,
	visibleAlbums: state.pouchDB.visibleAlbums,
	isLoading: state.pouchDB.isLoadingPhotos // Add this line
});

const mapDispatchToProps = (dispatch) => ({
	onPhotoClick: (index, albumIndex, eventDocID) => {
		dispatch(updateFullScreenPhotoIndex(index, '/events/' + eventDocID + '/' + albumIndex));
	},
	pageUpdated: (event, album, page) => {
		dispatch(photosPageSelected(event, album, page));
	},
	movePhotos: async (event, album, toAlbumDocId, photoDocIds) => {
		return dispatch(await movePhotosMAction(event, album, toAlbumDocId, photoDocIds));
	},
	deletePhotos: async (event, album, photoDocIds) => {
		return dispatch(await deletePhotosMAction(event, album, photoDocIds));
	},
	logoutNow: () => {
		dispatch(logout());
	},
	perPagePhotoCountChanged: (newPhotoCount) => {
		dispatch(perPagePhotoCountChangedMAction(newPhotoCount));
	}
});

const Photos = connect(mapStateToProps, mapDispatchToProps)(PhotoListComp);

export default Photos;
