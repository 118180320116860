import Subscription from '../pouchDB/models/Subscription';
import { ACTION_UPDATE_SUBSCRIPTION } from './action_types';

export const refreshSubscriptionDetails = () => {
	return async (dispatch, getState) => {
		try {
			const { userId, subscription } = getState().auth;
			if (!userId || !subscription?.id) {
				console.log('Cannot refresh subscription - missing userId or subscriptionId');
				return;
			}

			console.log('Refreshing subscription:', {
				userId,
				subscriptionId: subscription.id
			});

			const updatedSubscription = await Subscription.fetchSubscription(userId, subscription.id);

			console.log('Retrieved updated subscription:', {
				uploadLimit: updatedSubscription.uploadLimit,
				uploadedPhotos: updatedSubscription.uploadedPhotosCount,
				maxPhotosLimit: updatedSubscription.maxPhotosLimit,
				currentPhotos: updatedSubscription.currentPhotosCount
			});

			dispatch({
				type: ACTION_UPDATE_SUBSCRIPTION,
				payload: updatedSubscription
			});

			return updatedSubscription;
		} catch (error) {
			console.error('Failed to refresh subscription details:', error);
			throw error;
		}
	};
};
