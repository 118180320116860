import { Card, Col, Row } from 'antd';
import { CalendarRange, Package } from 'lucide-react';
import React from 'react';

const EventTypeSelector = ({ hasSubscription, onSelectType }) => {
	return (
		<Row gutter={16}>
			{hasSubscription && (
				<Col xs={24} md={12}>
					<Card hoverable onClick={() => onSelectType('subscription')} className="event-type-card">
						<CalendarRange size={48} className="event-type-icon" />
						<h3>Create Event from Subscription</h3>
						<p>This will create an event from your existing subscription pack</p>
					</Card>
				</Col>
			)}
			<Col xs={24} md={hasSubscription ? 12 : 24}>
				<Card hoverable onClick={() => onSelectType('pack')} className="event-type-card">
					<Package size={48} className="event-type-icon" />
					<h3>Create Event from Purchased Pack</h3>
					<p>You can use your already purchased packs to create an event</p>
				</Card>
			</Col>
		</Row>
	);
};

export default EventTypeSelector;
