import { Button, Card, Typography } from 'antd';
import { ArrowLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import EventForm from './EventForm';
import EventTypeSelector from './EventTypeSelector';
import PackSelector from './PackSelector';

const { Title } = Typography;

export const EVENT_NAME_LENGTH = 35;

const CreateEventComp = ({
	userId,
	whitelabelId,
	subscription,
	fetchPurchasedPacks,
	createEvent,
	eventCreated,
	goBack,
	goToDashboard
}) => {
	const [creationType, setCreationType] = useState(null);
	const [selectedPack, setSelectedPack] = useState(null);
	const [subscriptionValid, setSubscriptionValid] = useState(false);

	useEffect(() => {
		if (subscription) {
			validateSubscription(subscription);
		}
	}, [subscription]);

	const validateSubscription = (sub) => {
		if (!sub || !sub.id) {
			setSubscriptionValid(false);
			return;
		}
		const today = new Date();
		const expiryDate = new Date(sub.expiresAt);
		const isValid =
			sub.status === 'active' &&
			expiryDate > today &&
			sub.uploadedPhotosCount < sub.uploadLimit &&
			sub.currentPhotosCount < sub.maxPhotosLimit;
		setSubscriptionValid(isValid);
	};

	const handleTypeSelection = (type) => {
		setCreationType(type);
		setSelectedPack(null);
	};

	const handlePackSelection = (pack) => {
		setSelectedPack(pack);
	};

	const renderContent = () => {
		if (!creationType) {
			return (
				<EventTypeSelector
					hasSubscription={!!subscription && !!subscription.id}
					onSelectType={handleTypeSelection}
				/>
			);
		}
		if (creationType === 'subscription' && !subscriptionValid) {
			return (
				<>
					<p>
						{subscription && subscription.status !== 'active'
							? 'Your subscription has expired.'
							: 'You have reached the photo limit for your subscription plan. Please contact support for renewal.'}
					</p>
					<Button onClick={() => setCreationType(null)}>Go Back</Button>
				</>
			);
		}

		if (creationType === 'pack' && !selectedPack) {
			return (
				<PackSelector
					userId={userId}
					whitelabelId={whitelabelId}
					fetchPurchasedPacks={fetchPurchasedPacks}
					onPackSelect={handlePackSelection}
					goToDashboard={goToDashboard}
				/>
			);
		}

		return (
			<EventForm
				userId={userId}
				subscriptionId={subscription.id}
				selectedPackId={selectedPack ? selectedPack.id : null}
				createEvent={createEvent}
				eventCreated={eventCreated}
				creationType={creationType}
				subscription={subscription}
				selectedPack={selectedPack}
			/>
		);
	};

	return (
		<div className="create-event-container">
			<Button
				icon={<ArrowLeft size={16} />}
				onClick={goBack}
				style={{
					marginBottom: '20px',
					height: '36px',
					padding: '8px 16px',
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
					background: '#fff',
					border: '1px solid #d9d9d9',
					borderRadius: '6px',
					boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
				}}>
				Back
			</Button>
			<Card className="create-event-card">
				<Title level={2}>Create New Event</Title>
				{renderContent()}
			</Card>
		</div>
	);
};

export default CreateEventComp;
