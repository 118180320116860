import { PackageSearch } from 'lucide-react';
import React from 'react';

const NoPacksComp = () => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '24px',
				padding: '16px 24px',
				backgroundColor: 'white',
				borderRadius: '8px',
				boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.03)',
				border: '1px solid #f0f0f0',
				margin: '16px 0'
			}}>
			<PackageSearch size={32} color="#6F4898" strokeWidth={1.5} />

			<div>
				<h3
					style={{
						fontSize: '16px',
						fontWeight: '600',
						color: '#27272A',
						margin: '0 0 4px'
					}}>
					No Event Packs Available
				</h3>

				<p
					style={{
						fontSize: '14px',
						color: '#71717A',
						margin: 0,
						lineHeight: '1.4'
					}}>
					Purchase event pack to create events
				</p>
			</div>
		</div>
	);
};

export default NoPacksComp;
