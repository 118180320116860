import { Card } from 'antd';
import { ImageOff } from 'lucide-react';
import React from 'react';

const NoPhotosPlaceholder = () => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				padding: '40px 0'
			}}>
			<Card
				style={{
					display: 'inline-flex',
					boxShadow: 'none',
					border: 'none',
					background: 'transparent'
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '16px'
					}}>
					<ImageOff
						size={32}
						style={{
							color: '#6b7280',
							opacity: 0.8
						}}
						strokeWidth={1.5}
					/>
					<div style={{ textAlign: 'center' }}>
						<h3
							style={{
								fontSize: '16px',
								fontWeight: 500,
								color: '#374151',
								marginBottom: '8px'
							}}>
							No Photos Uploaded
						</h3>
						<p
							style={{
								fontSize: '14px',
								color: '#6b7280',
								margin: 0
							}}>
							Upload photos to your album to get started
						</p>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default NoPhotosPlaceholder;
